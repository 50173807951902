import {
  AfterViewInit,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  Barcode,
  BarcodeFormat,
  BarcodeScanner,
  LensFacing,
  StartScanOptions,
} from '@capacitor-mlkit/barcode-scanning';
import { ModalService } from '@services/modal.service';

import { ScannerConfig } from './scanner.module';
import { ScannerConfigToken } from './token';
import { addIcons } from 'ionicons';
import { close, flashlight } from 'ionicons/icons';

@Component({
  selector: 'native-scanner-modal',
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Scanning</ion-title>
        <ion-buttons slot="end">
          <ion-button (click)="closeModal()">
            <ion-icon name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content [fullscreen]="true">
      <ion-fab
        *ngIf="isTorchAvailable"
        slot="fixed"
        horizontal="end"
        vertical="bottom"
      >
        <ion-fab-button (click)="toggleTorch()">
          <ion-icon name="flashlight"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  `,
  styles: [
    `
      ion-content {
        --background: transparent;
      }

      .zoom-ratio-wrapper {
        position: absolute;
        left: 50%;
        bottom: 16px;
        transform: translateX(-50%);
        width: 50%;
      }
    `,
  ],
})
export class ScannerNativeFallbackModal
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() formats: BarcodeFormat[] = [];
  @Input() lensFacing: LensFacing = LensFacing.Back;

  public isTorchAvailable = false;
  public minZoomRatio: number | undefined;
  public maxZoomRatio: number | undefined;

  constructor(
    private modalService: ModalService,
    @Inject(ScannerConfigToken) private config: ScannerConfig
  ) {
    addIcons({ close, flashlight });
  }

  public ngOnInit(): void {
    BarcodeScanner.isTorchAvailable().then((result) => {
      this.isTorchAvailable = result.available;
    });
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.startScan();
    }, 250);
  }

  public ngOnDestroy(): void {
    this.stopScan();
  }

  public async closeModal(barcode?: Barcode): Promise<void> {
    this.modalService.dismiss(
      {
        barcode,
      },
      'success'
    );
  }

  public async toggleTorch(): Promise<void> {
    await BarcodeScanner.toggleTorch();
  }

  private async startScan(): Promise<void> {
    document
      .querySelector('body')
      ?.classList.add(this.config.nativeFallbackActiveClass);

    const options: StartScanOptions = {
      formats: this.formats,
      lensFacing: this.lensFacing,
    };
    const listener = await BarcodeScanner.addListener(
      'barcodeScanned',
      async (event) => {
        listener.remove();
        this.closeModal(event.barcode);
      }
    );
    await BarcodeScanner.startScan(options);
  }

  private async stopScan(): Promise<void> {
    document
      .querySelector('body')
      ?.classList.remove(this.config.nativeFallbackActiveClass);

    await BarcodeScanner.stopScan();
  }
}
