/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

export default {
  'tiff:Orientation'(value) {
    if (value === '1') {
      return 'Horizontal (normal)';
    }
    if (value === '2') {
      return 'Mirror horizontal';
    }
    if (value === '3') {
      return 'Rotate 180';
    }
    if (value === '4') {
      return 'Mirror vertical';
    }
    if (value === '5') {
      return 'Mirror horizontal and rotate 270 CW';
    }
    if (value === '6') {
      return 'Rotate 90 CW';
    }
    if (value === '7') {
      return 'Mirror horizontal and rotate 90 CW';
    }
    if (value === '8') {
      return 'Rotate 270 CW';
    }
    return value;
  },
  'exif:GPSLatitude': calculateGPSValue,
  'exif:GPSLongitude': calculateGPSValue
};
function calculateGPSValue(value) {
  const [degreesString, minutesString] = value.split(',');
  if (degreesString !== undefined && minutesString !== undefined) {
    const degrees = parseFloat(degreesString);
    const minutes = parseFloat(minutesString);
    const ref = minutesString.charAt(minutesString.length - 1);
    if (!Number.isNaN(degrees) && !Number.isNaN(minutes)) {
      return '' + (degrees + minutes / 60) + ref;
    }
  }
  return value;
}