import { Inject, Injectable } from '@angular/core';
import { Barcode, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Platform } from '@ionic/angular/standalone';
import { ModalService } from '@services/modal.service';

import { ScannerNativeFallbackModal } from './native-fallback-modal.component';
import { ScannerConfig } from './scanner.module';
import { ScannerConfigToken } from './token';
import { ScannerWebModalComponent } from './web-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  constructor(
    private platform: Platform,
    private modalService: ModalService,
    @Inject(ScannerConfigToken) private config: ScannerConfig
  ) {}

  public async start(): Promise<string> {
    if (this.platform.is('capacitor')) {
      const status = await BarcodeScanner.checkPermissions();
      if (status.camera !== 'granted') {
        throw new Error('NOT_GRANTED');
      }
      if (this.platform.is('ios')) {
        return this.startNative();
      } else {
        const isGoogleModuleAvailable =
          await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();

        if (isGoogleModuleAvailable.available) {
          console.info('GoogleBarcodeScannerModule is available or iOS.');
          return this.startNative();
        } else {
          console.info('GoogleBarcodeScannerModule is NOT available.');
          if (this.platform.is('capacitor') && this.platform.is('android')) {
            try {
              // install in the background
              BarcodeScanner.installGoogleBarcodeScannerModule();
            } catch (error) {
              console.error(
                'Failed to install GoogleBarcodeScannerModule',
                error
              );
            }
          }
        }

        return this.startFallbackNative();
      }
    } else {
      return this.startWeb();
    }
  }

  private async startNative(): Promise<string> {
    try {
      const { barcodes } = await BarcodeScanner.scan({
        formats: this.config.nativeFormats,
      });
      if (barcodes.length > 0) {
        return barcodes[0].displayValue;
      }
      return '';
    } catch (error) {
      return '';
    }
  }

  private async startFallbackNative(): Promise<string> {
    const modal = await this.modalService.createCSModal(
      ScannerNativeFallbackModal,
      {
        formats: this.config.nativeFormats,
      },
      {
        common: {
          cssClass: this.config.nativeFallbackClass,
        },
      }
    );
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'success') {
      return (data.barcode as Barcode)?.displayValue || '';
    }
    return '';
  }

  private async startWeb(): Promise<string> {
    const modal = await this.modalService.createCSModal(
      ScannerWebModalComponent,
      {
        formats: this.config.webFormats,
      }
    );
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'success') {
      if ('vibrate' in navigator) {
        navigator.vibrate(200);
      }
      return data.barcode;
    }
    throw new Error('User cancelled');
  }
}
