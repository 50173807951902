import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import Quagga, {
  QuaggaJSCodeReader,
  QuaggaJSConfigObject,
  QuaggaJSResultObject,
} from '@ericblade/quagga2';
import defaultsDeep from 'lodash.defaultsdeep';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DEFAULT_CONFIG } from './barcode-scanner-livestream.config';

@Component({
  selector: 'barcode-scanner-livestream',
  templateUrl: './barcode-scanner-livestream.component.html',
  styleUrls: ['./barcode-scanner-livestream.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BarcodeScannerLivestreamComponent implements OnChanges, OnDestroy {
  // Inputs
  @Input() types: QuaggaJSCodeReader[];

  @Input() deviceId: string;

  @Input() maxWidth = '100%';

  @Input() maxHeight: string;

  @Input() config: Partial<QuaggaJSConfigObject>;

  @Input() errorFilter: {
    median?: number;
    threshold?: number;
  };

  @Input() set torch(value: boolean) {
    const track = Quagga.CameraAccess.getActiveTrack();
    if (track) {
      track.applyConstraints({
        advanced: [{ torch: value } as any],
      });
    }
  }

  _valueChanges = new Subject();

  // Outputs
  @Output() valueChanges = new EventEmitter<QuaggaJSResultObject>();

  @Output() started = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<string>();

  @ViewChild('BarcodeScanner') barcodeScanner: ElementRef<HTMLDivElement>;

  get _maxWidth(): string {
    return this.maxWidth ? `${this.maxWidth}` : 'auto';
  }

  get _maxHeight(): string {
    return this.maxHeight ? `${this.maxHeight}` : 'auto';
  }

  private _started = false;

  get isStarted(): boolean {
    return this._started;
  }

  private _destroyed: Subject<boolean> = new Subject<boolean>();

  private configQuagga: QuaggaJSConfigObject;

  constructor() {
    this._valueChanges
      .pipe(
        takeUntil(this._destroyed)
        // filter((result: QuaggaJSResultObject) => {
        //   console.log({ result });
        //   const errors: number[] = result.codeResult.decodedCodes
        //     .filter((_) => _.error !== undefined)
        //     .map((_) => _.error);
        //   console.log({ errors });

        //   const median = this._getMedian(errors);
        //   console.log({ median });

        //   //Filter result when median and/or threshold parameters are provided
        //   //Good result for code_128 : median = 0.08 and threshold = 0.1
        //   return (
        //     !this.errorFilter ||
        //     !(
        //       (this.errorFilter.median && median > this.errorFilter.median) ||
        //       (this.errorFilter.threshold &&
        //         errors.some((err) => err > this.errorFilter.threshold))
        //     )
        //   );
        // })
      )
      .subscribe((result: QuaggaJSResultObject) => {
        const drawingCtx = Quagga.canvas.ctx.overlay;

        Quagga.ImageDebug.drawPath(
          result.line,
          {
            x: 'x',
            y: 'y',
          },
          drawingCtx,
          {
            color: 'red',
            lineWidth: 4,
          }
        );

        this.valueChanges.next(result);
      });
  }

  ngOnDestroy(): void {
    this.stop();
    this._destroyed.next(true);
    this._destroyed.complete();
  }

  ngOnChanges(): void {
    this.restart();
  }

  private _init(): Promise<void> {
    return new Promise((resolve, reject) => {
      Quagga.onProcessed((result) => this.onProcessed(result));

      Quagga.onDetected((result) => this.onDetected(result));

      this.configQuagga = defaultsDeep({}, this.config, DEFAULT_CONFIG);

      this.configQuagga.inputStream.target = this.barcodeScanner.nativeElement;

      if (this.types) {
        this.configQuagga.decoder.readers = this.types;
      }

      if (this.deviceId) {
        this.configQuagga.inputStream.constraints.deviceId = this.deviceId;
      }

      Quagga.init(this.configQuagga, (err) => {
        if (err) {
          console.log(err);
          return reject(err);
        }

        resolve();
      });
    });
  }

  private _getMedian(arr: number[]): number {
    arr.sort((a, b) => a - b);
    const half = Math.floor(arr.length / 2);
    if (arr.length % 2 === 1)
      // Odd length
      return arr[half];
    return (arr[half - 1] + arr[half]) / 2.0;
  }

  async checkPermission() {
    const perm = await navigator.permissions.query({ name: 'camera' } as any);

    if (perm.state === 'denied') {
      throw new Error(
        'The app does not have the permission to use the camera. Please enable the camera access in the OS/Browser settings'
      );
    }
  }
  async start(): Promise<void> {
    if (!this._started) {
      try {
        await this.checkPermission();
        await this._init();
        Quagga.start();
        this._started = true;
        this.started.next(true);
      } catch (error) {
        this.error.emit(error.message);
      }
    }
  }

  stop(): void {
    if (this._started) {
      Quagga.stop();
      this._started = false;
      this.started.next(false);
    }
  }

  restart(): void {
    if (this._started) {
      this.stop();
      this.start();
    }
  }

  onProcessed(result: QuaggaJSResultObject): any {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(
          0,
          0,
          parseInt(drawingCanvas.getAttribute('width'), 10),
          parseInt(drawingCanvas.getAttribute('height'), 10)
        );
        result.boxes
          .filter((box: any) => {
            return box !== result.box;
          })
          .forEach((box: any) => {
            Quagga.ImageDebug.drawPath(
              box,
              {
                x: 0,
                y: 1,
              },
              drawingCtx,
              {
                color: 'green',
                lineWidth: 2,
              }
            );
          });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(
          result.box,
          {
            x: 0,
            y: 1,
          },
          drawingCtx,
          {
            color: '#00F',
            lineWidth: 2,
          }
        );
      }
    }
  }

  onDetected(result: QuaggaJSResultObject): void {
    this._valueChanges.next(result);
  }
}
