import { QuaggaJSConfigObject, QuaggaJSCodeReader } from '@ericblade/quagga2';

export const DEFAULT_CONFIG: QuaggaJSConfigObject = {
  inputStream: {
    name: 'Live',
    type: 'LiveStream',
    target: null,
    constraints: {
      width: { min: 640 },
      height: { min: 480 },
      aspectRatio: { min: 1, max: 2 },
      facingMode: 'environment', // or user
    },
    singleChannel: false, // true: only the red color-channel is read
  },
  locator: {
    patchSize: 'medium',
    halfSample: true,
  },
  locate: true,
  numOfWorkers: 4,
  decoder: {
    readers: [
      'code_128_reader',
      'code_128_reader',
      'ean_reader',
      'ean_5_reader',
      'ean_2_reader',
      'ean_8_reader',
      'code_39_reader',
      'code_39_vin_reader',
      'codabar_reader',
      'upc_reader',
      'upc_e_reader',
      'i2of5_reader',
      '2of5_reader',
      'code_93_reader',
      'code_32_reader',
    ] as QuaggaJSCodeReader[],
  },
};
