/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

export default {
  'LightSource': value => {
    if (value === 1) {
      return 'Daylight';
    } else if (value === 2) {
      return 'Fluorescent';
    } else if (value === 3) {
      return 'Tungsten (incandescent light)';
    } else if (value === 4) {
      return 'Flash';
    } else if (value === 9) {
      return 'Fine weather';
    } else if (value === 10) {
      return 'Cloudy weather';
    } else if (value === 11) {
      return 'Shade';
    } else if (value === 12) {
      return 'Daylight fluorescent (D 5700 – 7100K)';
    } else if (value === 13) {
      return 'Day white fluorescent (N 4600 – 5400K)';
    } else if (value === 14) {
      return 'Cool white fluorescent (W 3900 – 4500K)';
    } else if (value === 15) {
      return 'White fluorescent (WW 3200 – 3700K)';
    } else if (value === 17) {
      return 'Standard light A';
    } else if (value === 18) {
      return 'Standard light B';
    } else if (value === 19) {
      return 'Standard light C';
    } else if (value === 20) {
      return 'D55';
    } else if (value === 21) {
      return 'D65';
    } else if (value === 22) {
      return 'D75';
    } else if (value === 23) {
      return 'D50';
    } else if (value === 24) {
      return 'ISO studio tungsten';
    } else if (value === 255) {
      return 'Other light source';
    }
    return 'Unknown';
  }
};