/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

export function getStringFromDataView(dataView, offset, length) {
  const chars = [];
  for (let i = 0; i < length && offset + i < dataView.byteLength; i++) {
    chars.push(dataView.getUint8(offset + i));
  }
  return getStringValueFromArray(chars);
}
export function getUnicodeStringFromDataView(dataView, offset, length) {
  const chars = [];
  for (let i = 0; i < length && offset + i < dataView.byteLength; i += 2) {
    chars.push(dataView.getUint16(offset + i));
  }
  return getStringValueFromArray(chars);
}
export function getStringValueFromArray(charArray) {
  return charArray.map(charCode => String.fromCharCode(charCode)).join('');
}
export function getCharacterArray(string) {
  return string.split('').map(character => character.charCodeAt(0));
}
export function objectAssign() {
  for (let i = 1; i < arguments.length; i++) {
    for (const property in arguments[i]) {
      arguments[0][property] = arguments[i][property];
    }
  }
  return arguments[0];
}
export function deferInit(object, key, initializer) {
  let initialized = false;
  Object.defineProperty(object, key, {
    get() {
      if (!initialized) {
        initialized = true;
        Object.defineProperty(object, key, {
          configurable: true,
          enumerable: true,
          value: initializer.apply(object),
          writable: true
        });
      }
      return object[key];
    },
    configurable: true,
    enumerable: true
  });
}
export function getBase64Image(image) {
  if (typeof btoa !== 'undefined') {
    // IE11- does not implement reduce on the Uint8Array prototype.
    return btoa(Array.prototype.reduce.call(new Uint8Array(image), (data, byte) => data + String.fromCharCode(byte), ''));
  }
  if (typeof Buffer === 'undefined') {
    return undefined;
  }
  if (typeof Buffer.from !== undefined) {
    // eslint-disable-line no-undef
    return Buffer.from(image).toString('base64'); // eslint-disable-line no-undef
  }
  return new Buffer(image).toString('base64'); // eslint-disable-line no-undef
}