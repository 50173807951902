/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at https://mozilla.org/MPL/2.0/. */

import { getStringFromDataView } from './utils.js';
import Constants from './constants.js';
export default {
  isPngFile,
  findPngOffsets
};
const PNG_ID = '\x89\x50\x4e\x47\x0d\x0a\x1a\x0a';
const PNG_CHUNK_LENGTH_SIZE = 4;
const PNG_CHUNK_TYPE_SIZE = 4;
const PNG_CHUNK_LENGTH_OFFSET = 0;
const PNG_CHUNK_TYPE_OFFSET = PNG_CHUNK_LENGTH_SIZE;
const PNG_CHUNK_DATA_OFFSET = PNG_CHUNK_LENGTH_SIZE + PNG_CHUNK_TYPE_SIZE;
const PNG_XMP_PREFIX = 'XML:com.adobe.xmp\x00';
function isPngFile(dataView) {
  return getStringFromDataView(dataView, 0, PNG_ID.length) === PNG_ID;
}
function findPngOffsets(dataView) {
  const PNG_CRC_SIZE = 4;
  const offsets = {
    hasAppMarkers: false
  };
  let offset = PNG_ID.length;
  while (offset + PNG_CHUNK_LENGTH_SIZE + PNG_CHUNK_TYPE_SIZE <= dataView.byteLength) {
    if (Constants.USE_PNG_FILE && isPngImageHeaderChunk(dataView, offset)) {
      offsets.hasAppMarkers = true;
      offsets.pngHeaderOffset = offset + PNG_CHUNK_DATA_OFFSET;
    } else if (Constants.USE_XMP && isPngXmpChunk(dataView, offset)) {
      const dataOffset = getPngXmpDataOffset(dataView, offset);
      if (dataOffset !== undefined) {
        offsets.hasAppMarkers = true;
        offsets.xmpChunks = [{
          dataOffset,
          length: dataView.getUint32(offset + PNG_CHUNK_LENGTH_OFFSET) - (dataOffset - (offset + PNG_CHUNK_DATA_OFFSET))
        }];
      }
    }
    offset += dataView.getUint32(offset + PNG_CHUNK_LENGTH_OFFSET) + PNG_CHUNK_LENGTH_SIZE + PNG_CHUNK_TYPE_SIZE + PNG_CRC_SIZE;
  }
  return offsets;
}
function isPngImageHeaderChunk(dataView, offset) {
  const PNG_CHUNK_TYPE_IMAGE_HEADER = 'IHDR';
  return getStringFromDataView(dataView, offset + PNG_CHUNK_TYPE_OFFSET, PNG_CHUNK_TYPE_SIZE) === PNG_CHUNK_TYPE_IMAGE_HEADER;
}
function isPngXmpChunk(dataView, offset) {
  const PNG_CHUNK_TYPE_INTERNATIONAL_TEXT = 'iTXt';
  return getStringFromDataView(dataView, offset + PNG_CHUNK_TYPE_OFFSET, PNG_CHUNK_TYPE_SIZE) === PNG_CHUNK_TYPE_INTERNATIONAL_TEXT && getStringFromDataView(dataView, offset + PNG_CHUNK_DATA_OFFSET, PNG_XMP_PREFIX.length) === PNG_XMP_PREFIX;
}
function getPngXmpDataOffset(dataView, offset) {
  const COMPRESSION_FLAG_SIZE = 1;
  const COMPRESSION_METHOD_SIZE = 1;
  offset += PNG_CHUNK_DATA_OFFSET + PNG_XMP_PREFIX.length + COMPRESSION_FLAG_SIZE + COMPRESSION_METHOD_SIZE;
  let numberOfNullSeparators = 0;
  while (numberOfNullSeparators < 2 && offset < dataView.byteLength) {
    if (dataView.getUint8(offset) === 0x00) {
      numberOfNullSeparators++;
    }
    offset++;
  }
  if (numberOfNullSeparators < 2) {
    return undefined;
  }
  return offset;
}